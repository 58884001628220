<template>
  <div style="caret-color: rgba(0, 0, 0, 0)">
    <div class="copyright">
    <div class="copyrightone">
      <a class="copyrightonea" href="https://beian.miit.gov.cn" target="_blank">© 聊城冰河创想网络科技有限公司版权所有 | 鲁ICP备19032689号</a>
    </div>
    <div class="lh">
      <div class="lhleft"></div>
      <div class="lhright">聊城冰河创想网络科技有限公司</div>
    </div>
    <div class="informatization">
      <a class="copyrightonea" target="_blank" href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=91330106MA2B2DR5X6-SAIC_SHOW_10000091330106MA2B2DR5X61658309880629&signData=MEQCID84PcRengUeUDFrBa6dWV5UdRNBQ8ql1y0IM3xpIHa1AiASklDPDTchtD1AbIk5vLhSWIubmmuaanvjgCE/Ot77ww==">营业执照：91371522MA3PQAR813</a>
      <span class="informatizationspan"></span>
    </div>
    
  </div>
  </div>
 
</template>

<script>
export default {

}
</script>

<style>
.copyright{
  /* width: 100%; */
  height: 125px;
  background-color: #111;
  padding: 30px 50px 40px;
  /* position: relative;
  bottom: 0; */
}
a{
  text-decoration:none
}
.copyrightonea{
  color: #666;
  font-size: 10px;
  display: inline-block;
  line-height: 12px;
}
.copyrightonea:hover{
  color: red;
}
.lh{
  display: flex;
  align-items: center;
  margin-top: 14px;
}
.lhleft{
  width: 3px;
  height: 12px;
  background-color: #666;
  /* margin-right: 3px; */
  margin: 0 4px;
}
.lhright{
  color: #666;
  font-size: 10px;
  caret-color: rgba(0, 0, 0, 0)
}
.informatizationspan{
  width: 3px;
  height: 12px;
  background-color: #666;
  /* margin-right: 3px; */
  margin: 0 4px;
  display: inline-block;
}
.informatization{
  margin-top: 8px;
}
</style>