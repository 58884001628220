<template>
  <div style="caret-color: rgba(0, 0, 0, 0)">
    <div class="headimg">
        <div style="caret-color: rgba(0, 0, 0, 0)" class="hometexttop">
            <h1>10亿人都想用的</h1><br>
            <h1>导购优惠返佣平台</h1>
        </div>
        <div style="caret-color: rgba(0, 0, 0, 0)" class="hometextbottom">
            <div class="hometextbottomtext">
            <img class="hometextbottomimg" src="../../img/icon_1.png" alt="">
            <span style="caret-color: rgba(0, 0, 0, 0)">自购轻松省</span>
            </div>
            <div class="hometextbottomtext">
            <img class="hometextbottomimg" src="../../img/icon_2.png" alt="">
            <span>分享简单转</span>
            </div>
            <div class="hometextbottomtext">
            <img class="hometextbottomimg" src="../../img/icon_3.png" alt="">
            <span>100%正品保障</span>
            </div>
        </div>

        <!-- 下载按钮 -->
        <div class="download">
            <a target="_blank" href="https://www.pgyer.com/shbb" class="downloadbut">
                <img src="../../img/download_and.png" alt="">
            </a>
            <a v-if="false" target="_blank" href="https://a.app.qq.com/o/simple.jsp?pkgname=com.n_add.android" class="downloadbut">
                <img src="../../img/download_ios.png" alt="">
            </a>
            <div  class="downloadbut" v-if="downloadbut"  @mouseover="qRcodentrue()" >
                <img src="../../img/icon_qrcode.png" alt="">
            </div>
            <div class="qRcode" v-if="qRcodenone" @mouseleave="qRcodentruefalse()">
                <img class="qRcodeimg" src="../../image/https___www.pgyer.com_shbb.png" alt="">
            </div>
           
        </div>


        <!-- <div class="homepageCenter" style="caret-color: rgba(0, 0, 0, 0)">
            <h1>4000万+用户的挚爱之选</h1>
            <div class="homepageCenterbox">
                <div class="homepageCenterboximg">
                    <img src="../../image/Snipaste_2023-03-21_10-36-10.png" alt="">
                    <img src="../../image/Snipaste_2023-03-21_10-36-46.png" alt="">
                </div>
                <div class="homepageCenterboxtext">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;拾惠邦作为全网全品类的社交推荐购物平台（买什么都省钱），平台依靠全网CPS，拾惠邦APP，拾惠邦社群和商家服务构建起了五位一体的社交化内容营销玩法。<br>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 拾惠邦一端链接淘宝、天猫、京东、拼多多、唯品会等全网最低价供应链，一端通过基于信任关系的社交推荐和基于大数据的个性化推荐能力，让“货”来找到“人”，打造未来主流的人货匹配模式。
                </div>
            </div>
        </div> -->
        
    </div>
    <div class="mod" style="caret-color: rgba(0, 0, 0, 0)">
        <h1>
            拾惠邦一站式省钱入口平台<br>吃喝玩乐住行全部为你搞定
        </h1>
        <div class="moddiv"></div>
        <h2>多 · 省 · 好 · 值</h2>
        <h1 class="modH1">
            你想要的这里都有
        </h1>
    </div>
    <div class="modmod" style="caret-color: rgba(0, 0, 0, 0)">
        <div class="modmodbig">
            <h2>“多”</h2>
            <h1 class="mess">
                做全网全品类商品和服务供给，接入全网平台海量优惠券，<br>
                为用户随时随地提供购物、餐饮、休闲娱乐及生活服务等领域的消费优惠，<br>
                让你足不出户买遍全球
            </h1>
            <img class="platform-icons" src="../../img/platform_icons.png" alt="">
            <h1 class="t1">电商Top平台一网打尽</h1>
            <h1 class="t2">接入淘宝、天猫、京东、拼多多、唯品会、苏宁易购、蘑菇街、饿了么、美团、<br>大众点评等平台海量优惠券</h1>
            
        </div>
    </div>
    <div class="mod4" style="caret-color: rgba(0, 0, 0, 0)">
        <h1>“省”</h1>
        <h2> 大额优惠劵免费领，海量低价优惠商品供你选择，购物先领劵，不花冤枉钱。<br>拾惠邦生活为亿万家庭精打细算节省每一笔网购开销。</h2>
       <div class="bottom">
        <div class="bottom1">
            <img src="../../img/icon_sheng1.png" alt="">
            <h1>你的省钱返佣神器</h1>
        </div>
        <div  class="bottom1">
            <img src="../../img/icon_sheng2.png" alt="">
            <h1>不止省钱还赚钱</h1>
        </div>
        <div  class="bottom1">
            <img src="../../img/icon_sheng3.png" alt="">
            <h1>一年轻松省下一个LV</h1>
        </div>
       </div>
    </div>
    <div class="mod5" style="caret-color: rgba(0, 0, 0, 0)">
        <h1>“好”</h1>
        <h2>
            拾惠邦优选源头好货一站购，深入产区、源头甄选，我们从供应产地的选择、<br>货运速度及售后等诸多方面来确保商品品质，让消费者能用更优惠的价格购买到更好的源头产品。
        </h2>
        <div class="mod5img">
            <img src="../../img/20200923_best2.png" alt="">
            <img src="../../img/20200923_best1.png" alt="">
            <img src="../../img/20200923_best3.png" alt="">
            <img src="../../img/20200923_best4.png" alt="">
            <img src="../../img/20200923_best5.png" alt="">
            <img src="../../img/20200923_best2.png" alt="">

        </div>
    </div>
    <div class="mod6" style="caret-color: rgba(0, 0, 0, 0)">
        <h1>“值”</h1>
        <h2>
            成为拾惠邦VIP会员，购物更划算，花的更少，赚的更多！同时拾惠邦生活为会员甄选全球优质资源，<br>提供衣、食、住、行、购、娱、大健康等高品质权益服务。
        </h2>
        <div class="box">
            <div class="boximgleft">
                <img src="../../img/pic_zhi_1.png" alt="">
            </div>
            <div class="boximgright">
                <img src="../../img/pic_zhi_2.png" alt="">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
 data(){
    return{
        qRcodenone:false,
        downloadbut:true
    }
 },
 methods:{
    qRcodentrue(){
        this.qRcodenone=true
        this.downloadbut=false
    },
    qRcodentruefalse(){
       this.qRcodenone=false,
       this.downloadbut=true
    }
 }
}
</script>

<style>
.headimg{
    padding:0 60px ;
    /* padding-bottom: 632px; */
    /* margin-bottom: 632px; */
    height: 595px;

    background: url(../../img/bg_index_header.jpg);
    background-size: 100% auto;
}
.hometexttop{
    /* padding:0 60px ; */
    padding-top: 130px;
    
    color: #fff;
    font-size: 30px;
}
.hometextbottom{
display: flex;
padding:30px 0 ;
font-size: 20px;
color: #fff;
}
.hometextbottomtext{
    display: flex;
align-items: center;  
margin-right: 40px;
}
.hometextbottomimg{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.download{
    display: flex;
    height: 60px;
    margin-top: 70px;
}
.downloadbut{
    margin-right: 20px;
}
.qRcode{
    width: 110px;
    height: 110px;
    /* display: none; */
}

.qRcodeimg{
    width: 100%;
}
.downloadbut:hover{
    background-color: #000;
    opacity: 0.7;
    /* display: none;/ */
}

/* .homepageCenter{
    background-color: #fff;
    height: 600px;
    margin: 90px 0;
    margin-top: 140px;
}
.homepageCenter h1{
    text-align: center;
    font-size: 60px;
}
.homepageCenterbox{
    margin-top: 70px;
    height: 320px;
    display: flex;
    flex: 1;
    font-size: 20px;
    font-weight: 400;

}
.homepageCenterboxtext{
    width: 60%;
}
.homepageCenterboximg{
   height: 100%;
   margin-right: 40px;
   width: 40%;
}
.homepageCenterboximg img{
   height: 100%;
   width: 40%;
   padding-left: 35px;
} */
.mod{
    /* padding:0 0px ; */
    height: 600px;
    background: url(../../img/bg_mod2.png);
}
.mod h1{
    color: #fff;
    text-align: center;
    padding: 30px 0 0 0;
    font-size: 50px;
}
.moddiv{
    /* text-align: center; */
    height: 2px;
    width: 40px;
    background-color: #fff;
    margin: 50px auto;
    border-radius: 40px;
}
.mod h2{
    font-size: 70px;
    color: #ffff;
    text-align: center;
}
.modH1{
    font-size: 45px;
    color: rgba(210, 201, 201, 0.6) !important
}
.modmod{
    height: 940px;
}
.modmodbig{
    padding: 90px 60px;
}
.modmodbig h2{
    text-align: center;
    font-size: 50px;
    font-weight: 400;
}
.mess{
    margin-top: 24px;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    color:rgba(54, 53, 53, 0.6) !important ;
}
.platform-icons{
    display: block;
    margin: 70px auto;
}
.t1{
    text-align: center;
    font-size: 25px;
    font-weight: 400;
}
.t2{
    text-align: center;
    color: rgb(129, 125, 125);
    font-size: 22px;
    font-weight: 400;
    margin-top: 20px;
}
.mod4{
    height: 800px;
    background: url(../../img/bg_mod4.png);
}
.mod4 h1{
    text-align: center;
    color: #fff;
    padding-top: 50px;
    font-size: 50px;
    font-weight: 500;
}
.mod4 h2{
    color: #fff;
    text-align: center;
    font-weight: 400;
    padding-top: 20px;

}
.bottom{
    width: 1112px;
    height: 399px;
    display: flex;
    margin: 90px auto;
    
}
.bottom1{
    flex: 1;
}
.bottom1 h1{
    display: flex;
    padding-left: 35px;
    justify-content: left;
    /* padding-top: 30px; */
    margin-top: 20px;
    padding-top: 0;
    font-size: 30px;
    font-weight: 500;

}
.mod5{
    margin: 0 auto;
        height: 1420px;
        
}
.mod5 h1{
    text-align: center;
    padding-top: 50px;
    font-size: 50px;
    font-weight: 500;
}
.mod5 h2{
    text-align: center;
    color: rgb(129, 125, 125);
font-weight: 400;
    padding-top: 20px;
}
.mod5img{
    width: 100%;
    margin: 40px 54.616px 0 54.602px;
}
.mod5img img{
width: 30%;
height: 537px;
margin-right: 10px;
}
.mod6{
    height: 880px;
    background: url(../../img/bg_mod6.png);
}

.mod6 h1{
    color: #fff;

    text-align: center;
    padding-top: 50px;
    font-size: 50px;
    font-weight: 500;
}
.mod6 h2{
    text-align: center;
    color: #fff;
font-weight: 400;
    padding-top: 20px;
}
.box{
    display: flex;
    width: 100%;
    height: 480px;
    margin: 70px 54.6px 0 54.6px;
}
.boximgleft{
    width: 50%;
    height: 480px;
    margin-right: 27px;
}

.boximgright{
    width: 50%;
    height: 480px;
}
</style>