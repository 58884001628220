<template>
  <div style="caret-color: rgba(0, 0, 0, 0)">
    <div class="aboutUsbaimg">
        <div class="aboutUsdiv">
            <img src="../../img/header_text (2).png" alt="">
        </div>
    </div>
    <div class="blacksilk">
        <div class="blacksilktext">
            <img src="../../img/title_about_1.png" alt="">
            <div class="companyProfile">
            <div class="companyprofileleft">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 冰河创想网络科技有限公司创立于2018年5月，总部位于山东聊城，旗下拥有领跑的全网全品类社交推荐购物平台拾惠邦APP（买什么都省钱）。公司现有员工近300人，核心团队全部来自阿里巴巴。<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 公司以产品技术为核心竞争力，合作和抓取了全网的优惠商品和服务，并基于大数据和AI算法，通过社交推荐和算法推荐，将卖家的高性价比好货推荐到消费者面前，持续优化网购消费体验，实现消费者、第三方平台以及卖家的三方链接。<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 未来，拾惠邦APP将长期致力于为亿万家庭精打细算节省每一笔网购开销，创造一种健康、环保、节约、绿色的全新生活方式。
                <!-- <div class="companyprofileleftbom">
                    <div class="companyprofileleftboml">
                        <img src="../../img/img_glory_1.png" alt="">
                    </div>
                </div> -->
            </div>
            <div class="companyprofileright">
                <img src="../../image/01a8ce5d19dd40a801215529200f78.jpg@1280w_1l_2o_100sh.jpg" alt="">
            </div>
        </div>
        </div>
      <div class="mission">
        <img src="../../img/img_smyijzg.png" alt="">
      </div>
      <div class="missionmod5">
        <div class="missionmod5img">
            <img src="../../img/title_about_5.png" alt="">
        </div>
        <div class="mod-content">
            <ul class="mod-contentul">
                <li>
                    <img src="../../image/20200923_img_honor_10.png" alt="">
                    <span>淘宝联盟2018官方优质合作伙伴</span>
                </li>
                <li>
                    <img src="../../image/20200923_img_honor_11 (1).png" alt="">
                    <span>美团2019官方优质合作伙伴</span>
                </li>
                <li><img src="../../image/20200923_img_honor_14.png" alt=""><span>2019中国金指尖奖#最佳电商创新平台#</span></li>
                <li><img src="../../image/20200923_img_honor_3.png" alt=""><span>#美博会#2020社交电商价值之星</span></li>
                <li><img src="../../image/20200923_img_honor_5.png" alt=""><span>2020中国金指尖奖#最佳新消费平台#</span></li>
                <li><img src="../../image/20200923_img_honor_6.png" alt=""><span>2019年度最佳社交电商大奖</span></li>
                <li><img src="../../image/20200923_img_honor_7.png" alt=""><span>2019年度最佳社交电商扶贫奖</span></li>
                <li><img src="../../image/20200923_img_honor_8.png" alt=""><span>淘宝联盟2018创客新星</span></li>
                <li><img src="../../image/20200923_img_honor_9.png" alt=""><span>淘宝联盟2019创新之星</span></li>
                <li><img src="../../image/20210713_img_honor_18.png" alt=""><span>中国MCN机构联盟发起人</span></li>
                <li><img src="../../image/20210713_img_honor_20.png" alt=""><span>2020年度数字经济平台大奖</span></li>
                <li><img src="../../image/20210713_img_honor_19.png" alt=""><span>2020年度中国公益企业</span></li>
            </ul>
        </div>
      </div>
      <div class="environment">
        <div class="environmenttop">
            <img src="../../image/title_about_2.png" alt="">
        </div>
        <div class="environmentbut">
            <img src="../../image/img_us_milieu_4.png" alt="">
            <img src="../../image/img_us_milieu_5.png" alt="">
            <img src="../../image/img_us_milieu_6.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.aboutUsbaimg{
    height: 595px;

    background: url(../../img/bg_about_header.png);
    background-size: 100% auto;
}
.aboutUsdiv{
    padding: 213px 83px 0 83px;
}
.blacksilk{
    height: 100%;
    background: url(../../img/bg_shading.png);
    background-color: #222;
    padding: 0 83px 0 83px;


}
.blacksilktext{
    width: 100%;
    /* height: 769px; */
    /* padding: 70px 83px 0 83px;    */

}
.companyProfile{
    display: flex;
    width: 100%;
}
.companyprofileleft{
    /* border-radius: 20px; */
    width: 50%;
    color: #fff;
    font-size: 18px;
    line-height: 34px;
    padding-top: 70px;
}
.companyprofileright{
    border-radius: 20px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
}
.blacksilktext img{
    padding-bottom: 30px;
}
.companyprofileright img{
    padding-top: 70px;
    width: 90%;
    height: 60%;

}
.companyprofileleftbom{
    display: flex;
    width: 100%;
    justify-content: center;
}
/* .companyprofileleftboml img{
    width: 75%;
    padding: 30px;
} */
.companyprofileleftbomr img{
    padding: 30px;
    width: 220px;
    height: 220px;
    
}
.mission{
    width: 100%;
    padding: 70px 0 0;
}
.mission img{
    width: 100%;
}
.missionmod5{
    padding: 70px 0 0;

}
li {
    list-style-type:none
}
.mod-contentul{
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
}
.mod-contentul li{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-top: 15px;
}
.mod-contentul img{
    width: 230px;
    height: 230px;
    margin: 0 auto;
}
.mod-contentul span{
    color: #fff;
    text-align: center; 
    padding: 10px 0;
}
.environmenttop{
    padding: 50px 0;
}
.environmentbut img{
    width: 32%;
    padding: 0 7px;  
}
</style>