<template>
  <div style="caret-color: rgba(0, 0, 0, 0)">
    <div class="header">
        <div class="headertext">
            <h1>拾惠邦</h1>
            <h1>源头好货一站购</h1>
            <h4>源头开采  口碑爆品  正品保障  超低价格  超级实惠  超级优惠</h4>
        </div>
       
    </div>
    <div class="content">
            <h1 class="b contenth1">拾惠邦<br>为用户提供极致性价比的商品</h1>
            <h3 class="b contenth2">让用户的选择变的简单</h3>
            <h2 class="b contenth3">包含众多项目</h2>
            <div class="contentdiv">
                <div class="contendivbig">
                    <img class="contentimg" src="../../img/20200923_category_1.png">
                <div class="contenttext b">生鲜美食</div>
                </div>
                <div class="contendivbig">
                    <img class="contentimg" src="../../img/20200923_category_2.png" alt="">
                <div class="contenttext b">箱包服饰</div>
                </div>
                <div class="contendivbig">
                    <img class="contentimg" src="../../img/20200923_category_3.png" alt="">
                <div class="contenttext b">母婴</div>
                </div>
                <div class="contendivbig">
                    <img class="contentimg" src="../../img/20200923_category_4.png" alt="">
                <div class="contenttext b">家居家电</div>
                </div>
                <div class="contendivbig">
                    <img class="contentimg" src="../../img/20200923_category_5.png" alt="">
                <div class="contenttext b">美妆</div>
                
                </div>
                <div class="contendivbig">
                    <img class="contentimg" src="../../img/20200923_category_6.png">
                <div class="contenttext b">营养保健</div>

                </div>
            </div>
            <div class="mod3">
                <h1 class="b mod3h1">原产地直采</h1>
                <h2 class="b mod3h2">深入工厂、田间，挖掘性价比源头好货</h2>
                <div class="mod3div">
                    <img src="../../img/img_sourcemap.png" alt="">
                </div>
            </div>
            <div class="publicpraise">
                <h1 class="mod4h1 b mod3h1">口碑爆品 </h1>
                <h2 class="mod4h2 b mod3h2">剔除品牌溢价和中间环节，为国人甄选高品质的天下优品</h2>
                <div class="publicpraisediv">
                    <img src="../../img/20200923_img_hot_1.png" alt="">
                    <img src="../../img/20200923_img_hot_2.png" alt="">
                    <img src="../../img/20200923_img_hot_3.png" alt="">
                </div>
            </div>
            <div class="partner">
                <h1 class="mod4h1 b mod3h1">合作品牌 </h1>
               <img src="../../img/partner_logo.png" alt="">
            </div>
        </div>
  </div>
</template>

<script>

export default {

}
</script>

<style>
.header{
    height: 595px;

    background: url(../../img/bg_preference_header.png);
    background-size: 100% auto;
}
.headertext{
    height: 165px;
    padding: 213px 54px 0 54px;
}
.headertext h1{
    color: #fff;
    font-size: 55px;
}
.headertext h4{
    color: #fff;
    padding: 5px  0;
    font-size: 25px;
    font-weight: 400;
}
.content{
    /* margin-top: 250px; */
    /* margin: 0 auto; */
    /* height: 3234px; */
    background: url(../../img/bg_shading.png);
    background-color: #222;
}
.b{
    color: #fff;
}
.contenth1{
    /* color: #fff; */
    font-size: 55px;
    /* margin-top: 98px; */
    padding: 213px 54px 0 54px;

    padding-top: 98px;

}
.contenth3{
    padding-top: 20px;
    font-size: 25px;
    padding-left: 54px;
    font-weight: 400;
}
.contenth2{
    font-size: 50px;   
     padding: 55px 54px 0 54px;

}
.contentdiv{
    width: 100%;
    display: flex;
    padding: 0px 54.6px 60px 54.6px;

}
.contendivbig{
    width: 15%;
    height: 268px;
}
.contentimg {
    width: 100%;
}
.contendivbig .contenttext{
    width: 100%;
    text-align: center;
    padding-top: 20px;
    color: #ffe8d6 !important;
    font-size: 18px;
}
.mod3{
    padding: 98px 54.6px 60px 54.6px;

}
.mod3h1{
    font-size: 55px;
    margin-bottom: 16px;
}
.mod3h2{
    font-size: 25px;
    margin-bottom: 60px;

}
.mod3div{
    width: 100%;
}
.mod3div img{
    width: 100%;
}
.publicpraise{
    padding: 0 54.6px 60px 54.6px;
}
.publicpraisediv{
    width: 100%;
}
.publicpraisediv img{
    /* padding-left: 10px; */
    width: 31%;
    padding-right: 20px;
}
.partner{
    padding: 0 54.6px 60px 54.6px;

}
.partner img{
    width: 100%;
    padding: 20px 0;
}
</style>