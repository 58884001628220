var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"caret-color":"rgba(0, 0, 0, 0)"}},[_c('div',{staticClass:"headimg"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"download"},[_vm._m(2),(false)?_c('a',{staticClass:"downloadbut",attrs:{"target":"_blank","href":"https://a.app.qq.com/o/simple.jsp?pkgname=com.n_add.android"}},[_c('img',{attrs:{"src":require("../../img/download_ios.png"),"alt":""}})]):_vm._e(),(_vm.downloadbut)?_c('div',{staticClass:"downloadbut",on:{"mouseover":function($event){return _vm.qRcodentrue()}}},[_c('img',{attrs:{"src":require("../../img/icon_qrcode.png"),"alt":""}})]):_vm._e(),(_vm.qRcodenone)?_c('div',{staticClass:"qRcode",on:{"mouseleave":function($event){return _vm.qRcodentruefalse()}}},[_c('img',{staticClass:"qRcodeimg",attrs:{"src":require("../../image/https___www.pgyer.com_shbb.png"),"alt":""}})]):_vm._e()])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hometexttop",staticStyle:{"caret-color":"rgba(0, 0, 0, 0)"}},[_c('h1',[_vm._v("10亿人都想用的")]),_c('br'),_c('h1',[_vm._v("导购优惠返佣平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hometextbottom",staticStyle:{"caret-color":"rgba(0, 0, 0, 0)"}},[_c('div',{staticClass:"hometextbottomtext"},[_c('img',{staticClass:"hometextbottomimg",attrs:{"src":require("../../img/icon_1.png"),"alt":""}}),_c('span',{staticStyle:{"caret-color":"rgba(0, 0, 0, 0)"}},[_vm._v("自购轻松省")])]),_c('div',{staticClass:"hometextbottomtext"},[_c('img',{staticClass:"hometextbottomimg",attrs:{"src":require("../../img/icon_2.png"),"alt":""}}),_c('span',[_vm._v("分享简单转")])]),_c('div',{staticClass:"hometextbottomtext"},[_c('img',{staticClass:"hometextbottomimg",attrs:{"src":require("../../img/icon_3.png"),"alt":""}}),_c('span',[_vm._v("100%正品保障")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"downloadbut",attrs:{"target":"_blank","href":"https://www.pgyer.com/shbb"}},[_c('img',{attrs:{"src":require("../../img/download_and.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mod",staticStyle:{"caret-color":"rgba(0, 0, 0, 0)"}},[_c('h1',[_vm._v(" 拾惠邦一站式省钱入口平台"),_c('br'),_vm._v("吃喝玩乐住行全部为你搞定 ")]),_c('div',{staticClass:"moddiv"}),_c('h2',[_vm._v("多 · 省 · 好 · 值")]),_c('h1',{staticClass:"modH1"},[_vm._v(" 你想要的这里都有 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modmod",staticStyle:{"caret-color":"rgba(0, 0, 0, 0)"}},[_c('div',{staticClass:"modmodbig"},[_c('h2',[_vm._v("“多”")]),_c('h1',{staticClass:"mess"},[_vm._v(" 做全网全品类商品和服务供给，接入全网平台海量优惠券，"),_c('br'),_vm._v(" 为用户随时随地提供购物、餐饮、休闲娱乐及生活服务等领域的消费优惠，"),_c('br'),_vm._v(" 让你足不出户买遍全球 ")]),_c('img',{staticClass:"platform-icons",attrs:{"src":require("../../img/platform_icons.png"),"alt":""}}),_c('h1',{staticClass:"t1"},[_vm._v("电商Top平台一网打尽")]),_c('h1',{staticClass:"t2"},[_vm._v("接入淘宝、天猫、京东、拼多多、唯品会、苏宁易购、蘑菇街、饿了么、美团、"),_c('br'),_vm._v("大众点评等平台海量优惠券")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mod4",staticStyle:{"caret-color":"rgba(0, 0, 0, 0)"}},[_c('h1',[_vm._v("“省”")]),_c('h2',[_vm._v(" 大额优惠劵免费领，海量低价优惠商品供你选择，购物先领劵，不花冤枉钱。"),_c('br'),_vm._v("拾惠邦生活为亿万家庭精打细算节省每一笔网购开销。")]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"bottom1"},[_c('img',{attrs:{"src":require("../../img/icon_sheng1.png"),"alt":""}}),_c('h1',[_vm._v("你的省钱返佣神器")])]),_c('div',{staticClass:"bottom1"},[_c('img',{attrs:{"src":require("../../img/icon_sheng2.png"),"alt":""}}),_c('h1',[_vm._v("不止省钱还赚钱")])]),_c('div',{staticClass:"bottom1"},[_c('img',{attrs:{"src":require("../../img/icon_sheng3.png"),"alt":""}}),_c('h1',[_vm._v("一年轻松省下一个LV")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mod5",staticStyle:{"caret-color":"rgba(0, 0, 0, 0)"}},[_c('h1',[_vm._v("“好”")]),_c('h2',[_vm._v(" 拾惠邦优选源头好货一站购，深入产区、源头甄选，我们从供应产地的选择、"),_c('br'),_vm._v("货运速度及售后等诸多方面来确保商品品质，让消费者能用更优惠的价格购买到更好的源头产品。 ")]),_c('div',{staticClass:"mod5img"},[_c('img',{attrs:{"src":require("../../img/20200923_best2.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../img/20200923_best1.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../img/20200923_best3.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../img/20200923_best4.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../img/20200923_best5.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../img/20200923_best2.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mod6",staticStyle:{"caret-color":"rgba(0, 0, 0, 0)"}},[_c('h1',[_vm._v("“值”")]),_c('h2',[_vm._v(" 成为拾惠邦VIP会员，购物更划算，花的更少，赚的更多！同时拾惠邦生活为会员甄选全球优质资源，"),_c('br'),_vm._v("提供衣、食、住、行、购、娱、大健康等高品质权益服务。 ")]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"boximgleft"},[_c('img',{attrs:{"src":require("../../img/pic_zhi_1.png"),"alt":""}})]),_c('div',{staticClass:"boximgright"},[_c('img',{attrs:{"src":require("../../img/pic_zhi_2.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }