<template>
  <div class="body">
        <div class="headtop">
    <div class="headtopleft">
        <img class="headtopleftimg" src="../img/微信图片_20230317162123.jpg" alt="">
        <!-- <span>拾惠邦</span> -->
    </div>
    <div class="headtextright">
        <span :class="{colorh:col}" @click="homePagetrue($event)">首页</span>
        <span :class="{colorh1:col1}" @click="homePagetruetrue()">拾惠邦精选</span>
        <span :class="{colorh2:col2}" @click="memBertrue()">会员权益</span>
        <!-- <span>新闻中心</span> -->
        <span :class="{colorh3:col3}" @click="businessCooperationtrue()">商务合作</span>
        <span :class="{colorh4:col4}" @click="AboutUstrue()">关于我们</span>
        <span :class="{colorh5:col5}" @click="SelfOperated()">自营介绍</span>
    </div>
   </div>

   <!-- 首页部分展示 -->
   <homePage v-if="homePage"></homePage>
   <!-- 关于我们 -->
   <companyProfile v-if="companyProfile"></companyProfile>
   <memBer v-if="menBer"> </memBer>
   <businessCooperation v-if="businessCooperation"></businessCooperation>
   <AboutUs v-if="AboutUs"></AboutUs>
   <!-- <buttom class="gotop">回到顶部</buttom> -->
   
   <!-- 自营介绍 -->
   <Selfoperated v-if="Selfoperatedtrue"></Selfoperated>
   <agreeMenttrue v-if="agreeMenttrue"></agreeMenttrue>

   <!-- <div @click="toTop()">去上面</div> -->
  </div>
</template>

<script>
import companyProfile from './components/companyProfile.vue';
import homePage from './components/homePage.vue';
import memBer from './components/memBerList.vue';
import businessCooperation from './components/businessCooperation.vue';
import AboutUs from './components/aboutUs.vue';
import agreeMenttrue from './components/agreeMenttrue.vue'
import Selfoperated from './components/selfOperated.vue'
export default {
    components:{
        homePage,
        companyProfile,
        memBer,
        businessCooperation,
        AboutUs,
        agreeMenttrue,
        Selfoperated
    },
    data(){
        return{
            homePage:true,
            companyProfile:false,
            menBer:false,
            businessCooperation:false,
            AboutUs:false,
            agreeMenttrue:true,
            Selfoperatedtrue:false,
            col:true,
            col1:false,
            col2:false,
            col3:false,
            col4:false,
            col5:false
        }
      
    },
    methods:{
        homePagetrue(event){
            console.log(event)
            // event.currentTarget.className = "classname"
            this.homePage=true
            this.companyProfile=false
            this.menBer=false
            this.businessCooperation=false
            this.AboutUs=false
            this.Selfoperatedtrue=false
            document.documentElement.scrollTop = 0;
            this.col=true
            this.col1=false
            this.col2=false
            this.col3=false
            this.col4=false
            this.col5=false
        },
        homePagetruetrue(){
            this.homePage=false
            this.companyProfile=true
            this.menBer=false
            this.businessCooperation=false
            this.AboutUs=false
            // this.agreeMenttrue=false
            this.Selfoperatedtrue=false
            document.documentElement.scrollTop = 0;
            this.col=false
            this.col1=true
            this.col2=false
            this.col3=false
            this.col4=false
            this.col5=false

        },
        memBertrue(){
            this.homePage=false
            this.companyProfile=false
            this.menBer=true
            this.businessCooperation=false
            this.AboutUs=false
            this.Selfoperatedtrue=false
            document.documentElement.scrollTop = 0;
            this.col=false
            this.col1=false
            this.col2=true
            this.col3=false
            this.col4=false
            this.col5=false

        },
        businessCooperationtrue(){
            this.homePage=false
            this.companyProfile=false
            this.menBer=false
            this.businessCooperation=true
            this.AboutUs=false
            this.Selfoperatedtrue=false
            document.documentElement.scrollTop = 0;
            this.col=false
            this.col1=false
            this.col2=false
            this.col3=true
            this.col4=false
            this.col5=false

        },
        AboutUstrue(){
            this.homePage=false
            this.companyProfile=false
            this.menBer=false
            this.businessCooperation=false
            this.AboutUs=true
            this.Selfoperatedtrue=false

            document.documentElement.scrollTop = 0;
            this.col=false
            this.col1=false
            this.col2=false
            this.col3=false
            this.col4=true
            this.col5=false

        },
        SelfOperated(){
            this.homePage=false
            this.companyProfile=false
            this.menBer=false
            this.businessCooperation=false
            this.AboutUs=false
            this.Selfoperatedtrue=true
            document.documentElement.scrollTop = 0;
            this.col=false
            this.col1=false
            this.col2=false
            this.col3=false
            this.col4=false
            this.col5=true
        }
        // toTop(){
        //     document.documentElement.scrollTop = 0;
        // }
    }

}
// const button = document.querySelector('button')
// button.addEventListener('click',function(){
//     const timeid =setInterval(function(){
//         const scrolltop =document.documentElement.scrollTop
//         if(scrolltop<=0){
//             clearInterval(timeid)
//         } else {
//             window.scroll(0,scrolltop-100)
//         }
//     },10)
// })
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
.body{
    box-sizing: border-box;
    height: 20px;
    /* background-color: aqua; */
    
}
.colorh{
    color: rgb(233, 150, 25);
}
.colorh1{
    color: rgb(233, 150, 25);
}
.colorh2{
    color: rgb(233, 150, 25);
}
.colorh3{
    color: rgb(233, 150, 25);
}
.colorh4{
    color: rgb(233, 150, 25);
}
.headtop{
        padding: 0 104px;
        align-items: center;
        display: flex;
        width: 100%;
        height: 60px;
        background-color:rgba(0, 0, 0, 0.6);
        position: fixed;
        
    }
/* .headimg{
    height: 632px;
    background: url(../img/bg_preference_header.png);
} */
.headtextright{
    color: #ffff;
    
}
.headtextright span{
    margin: 0 20px;
    cursor:pointer;
    caret-color: rgba(0, 0, 0, 0)

}
.headtopleftimg{
    width: 50px;
    height: 50px;
    border-radius: 30%;
}
.classname{
    color: red;
}
.headtopleft{
    width: 40%;
}
.headtextright{
    width: 60%;
}
</style>