<template>
  <div style="caret-color: rgba(0, 0, 0, 0)">
    <div class="bustop">
        <div class="coopertop">
            <img src="../../img/header_text (1).png" alt="">
        </div>
        
    </div>
    <div class="butbottomdiv">
      <ul class="butbottoms">
      <li>商务合作： fxhz@fenxianglife.com</li>
      <li>招商合作（CPS）：gwzs@fenxianglife.com</li>
      <li>招商合作（自营-粉象优选）： youxuan@fenxianglife.com</li>
      <li>
        <span>查看自营-粉象优选合作条件，请点击：</span>
        <a href="https://www.fenxianglife.com/news/detail?id=28"></a>
      </li>
      <li>媒体合作： yujiangping@fenxianglife.com</li>
      <li>线下合作：yujiangping@fenxianglife.com  </li>
      <li>人才招聘：hrzp@fenxianglife.com</li>
    </ul>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>
.bustop{  
  height: 595px;

    background: url(../../img/bg_cooperation_header.png);
    background-size: 100% auto;
}
.coopertop{
    padding: 231px 54px 0;
}
.butbottoms li{
  color: #fff;
  padding-bottom: 10px;
}
.butbottomdiv{
  background-color: #111;
  /* background: url(../../img/bg_shading.png); */


}
.butbottoms{
  margin: 0 83px;
  padding: 50px 0;
  width: 100%;
  display: inline-block;
  background: url(../../img/bg_shading.png);
}
</style>