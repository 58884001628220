<template>
  <div style="caret-color: rgba(0, 0, 0, 0)">
    <div class="member">
      <div class="memberdiv">
        <img src="../../img/header_text.png" alt="">
      </div>
    </div>
    <div class="activateVIP">
      <div class="activatemember">
        <h3 class="b activatemembertext">拾惠邦会员专享权益</h3>
        <div class="activatememberdiv">
          <img src="../../img/img_vip_1.png" alt="">
          <img src="../../img/img_vip_2.png" alt="">
          <img src="../../img/img_vip_3.png" alt="">
          <img src="../../img/img_vip_4.png" alt="">
          <img src="../../img/img_vip_5.png" alt="">
        </div>
        <div class="activatememberimg">
          <img src="../../img/img_vip_min_1.png" alt="">
          <img src="../../img/img_vip_min_2.png" alt="">
          <img src="../../img/img_vip_min_3.png" alt="">
        </div>
        <div class="activatememberbottom">
         <h1 class="b">千万用户的选择</h1>
         <h2 class="b">此刻，在全球 48 个国家和地区，有 3000万 用户 在使用拾惠邦享受全网优惠</h2>
         <div class="activatememberbottomdiv">
          <!-- <div class="activatememberbottomdivleft">
            <span class="activatememberbottomdivlefttop">
              <img src="../../img/icon_pricetag.png" alt="">
              <h3>累计为用户节省</h3>
            </span>
            <span class="activatememberbottomdivlefttop">
              <img src="../../img/icon_pricetag.png" alt="">
              <h3>累计为会员赚取</h3>
            </span>
          </div> -->
          <div class="activatememberbottomdivleft">

            <div class="activatememberbottomdivleftTop">
              <div class="activatememberbottomdivleftTopLeft">
                <img src="../../img/icon_pricetag.png" alt="">
              <h3>累计为用户节省</h3>
              
              
              </div>
              <div class="activatememberbottomdivleftTopLeft activatememberbottomdivleftTopRight">
                <img src="../../img/icon_pricetag.png" alt="">
              <h3>累计为会员赚取</h3>
              </div>
            </div>
            <div class="activatememberbottomdivleftBottom">
              <div>
                <span class="nubell">18</span>
              <span class="nubell2">忆+</span>
              </div>
              <div class="nubellright">
                <span class="nubell">10</span>
              <span class="nubell2">忆+</span>
              </div>
            </div>
            <div>
              <h1 class="saleroomtop">共产生销售额</h1>
              <span class="saleroombottom1">10,000,000,000+</span>
            </div>
          </div>
          <div class="activatememberbottomdivright">
            <img src="../../img/img_worldmap.png" alt="">
          </div>
         </div>
         <div class="fuzhiimg">
          <img src="../../img/Snipaste_2023-03-17_17-34-31.png" alt="">
         </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.member{
  background: url(../../img/bg_vip_header.png);
  background-size: 100% auto;
  height: 595px;
}
.memberdiv{
  padding: 190px 54.3px 0 54.3px;
}
/* .activateVIP{
  height: 840px;
} */
.activatemember{
  background: url(../../img/bg_shading.png);
  /* height: 840px; */
  background-color: #222;
  padding: 98px 54.3px;
}
.activatemembertext{
  font-size: 50px;
}
.activatememberdiv{
  width: 100%;
padding: 20px 54.3px 0 0px;
}
.activatememberdiv img{
  width: 19%;
}
.activatememberimg{
padding: 20px 54.3px 0 0px;
width: 100%;
}
.activatememberimg img{
  margin: 10px 0;
  width: 100%;
}
.activatememberbottom{
  height: 616px;
}
.activatememberbottom h1{
  font-size: 55px;
}
.activatememberbottom h2{
  font-size: 25px;
  padding-top: 20px;
  padding-bottom: 80px;
}
.activatememberbottomdiv{
  height: 290px;
  display: flex;
  /* width: 50%; */
}
.activatememberbottomdivleft{
  width: 50%;
}
.activatememberbottomdivleftTop{
  display: flex;
}
.activatememberbottomdivleftTopLeft{
  display: flex;
  /* padding-left: 20px; */
}
.activatememberbottomdivleftTopRight{
  padding-left: 135px;
  padding-right: 170px;
}
.activatememberbottomdivleftTopLeft h3{
  color: #fff;
}
.activatememberbottomdivleftTopLeft img{
  padding-top: 5px;
  padding-right: 3px;
  width: 20px;
  height: 20px;
}
.activatememberbottomdivleftBottom{
  color: #fff;
  display: flex;
  padding-top: 20px;
}
.activatememberbottomdivright{
  width: 50%;
}
.activatememberbottomdivright img{
  width: 100%;
  height: 100%;
}
.nubell{
  font-size: 55px;
  font-weight: 700;
}
.nubell2{
font-size: 30px;
font-weight: 700;
}
.nubellright{
  padding-left: 160px;
}
.saleroomtop{
  padding-top: 36px;
  color: #fff;
  font-size: 30px !important;
}
.saleroombottom1{
  font-size: 60px;
  color: #fff;

}
.fuzhiimg{
  width: 100%;
  height: 171px;
}
.fuzhiimg img{
  padding: 30px ;
  width: 100%;
  height: 100%;
}
</style>