<template>
  <div>
    <div class="sopd">
        <div class="operated" style="caret-color: rgba(0, 0, 0, 0)">
            <h1>4000万+用户的挚爱之选</h1>
            <div class="operatedbox">
                <div class="operatedboximg">
                    <img src="../../image/Snipaste_2023-03-21_10-36-10.png" alt="">
                    <img src="../../image/Snipaste_2023-03-21_10-36-46.png" alt="">
                </div>
                <div class="operatedboxtext">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;拾惠邦作为全网全品类的社交推荐购物平台（买什么都省钱），平台依靠全网CPS，拾惠邦APP，拾惠邦社群和商家服务构建起了五位一体的社交化内容营销玩法。<br>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 拾惠邦一端链接淘宝、天猫、京东、拼多多、唯品会等全网最低价供应链，一端通过基于信任关系的社交推荐和基于大数据的个性化推荐能力，让“货”来找到“人”，打造未来主流的人货匹配模式。
                </div>
            </div>
        </div>
        
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.sopd{
    /* background-color: aqua; */
    padding: 0 60px;
    padding-top: 60px;
    
}
.operated{
    background-color: #fff;
    height: 600px;
    margin: 90px 0;
    margin-top: 140px;
}
.operated h1{
    text-align: center;
    font-size: 60px;
}
.operatedbox{
    margin-top: 70px;
    height: 320px;
    display: flex;
    flex: 1;
    font-size: 20px;
    font-weight: 400;

}
.operatedboxtext{
    padding-top: 60px;
    width: 60%;
    /* text-align: center; */
}
.operatedboximg{
   height: 100%;
   margin-right: 40px;
   width: 40%;
}
.operatedboximg img{
   height: 100%;
   width: 40%;
   padding-left: 35px;
   /* margin-bottom: 30px; */
}
</style>